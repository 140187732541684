import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import "./LoginPage.css"
import { ReactComponent as UsernameSvg } from '../../asset/images/svg/username.svg';
import { ReactComponent as PasswordSvg } from '../../asset/images/svg/password.svg';
import { ReactComponent as GoogleSvg } from '../../asset/images/svg/google.svg';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    Button,
} from "@mui/material";
import { Checkbox } from 'primereact/checkbox';
import axios from 'axios';
import Cookies from "js-cookie";
import CircularProgress from '@mui/material/CircularProgress';
const MAX_LOGIN_ATTEMPTS = 3;


export const LoginPage = ({ url }) => {

    const [isChecked, setIsChecked] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [loginAttempts, setLoginAttempts] = useState(0);
    const [subtitledialdg, setSubtitleDialog] = useState('');
    const [waitingPeriod, setWaitingPeriod] = useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const openErrorDialog = () => {
        setErrorDialogOpen(true);
    };

    const handleLoginFailure = () => {
        setLoginAttempts((prevAttempts) => prevAttempts + 1);

        if (loginAttempts === MAX_LOGIN_ATTEMPTS - 1) {
            // Reached maximum login attempts

            setSubtitleDialog("Reached maximum login attempts");
            openErrorDialog();
            startWaitingPeriod();
        }
    };

    const startWaitingPeriod = () => {
        setWaitingPeriod(true);

        setTimeout(() => {
            setWaitingPeriod(false);
            setLoginAttempts(0);
        }, 5 * 60 * 1000); // 5 minutes in milliseconds
    };

    const handleLogin = async () => {
        if (!username && !password) {
            setUsernameError(true);
            setPasswordError(true);
            return;
        }

        if (!username) {
            setUsernameError(true);
            return;
        }
        else {
            setUsernameError(false);
        }

        if (!password) {
            setPasswordError(true);
            return;
        }
        else {
            setPasswordError(false);
        }
        if (waitingPeriod) {
            setSubtitleDialog("You must wait five minutes to try again");
            openErrorDialog();
            return;
        }

        if (loginAttempts >= 3) {
            setSubtitleDialog("Reached maximum login attempts");
            openErrorDialog();
            return;
        }


        setLoading(true);
        try {
            axios.defaults.withCredentials = true;

            const response = await axios.get(url, {
                params: {
                    usr: username,
                    pwd: password,
                    cmd: "login",
                    withCredentials: true,

                },
                credentials: 'include',

                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Credentials': true,
                    'Access-Control-Allow-Origin': true,
                    'Access-Control-Expose-Headers': '*',
                    'Access-Control-Allow-Headers': '*',
                    // 'withCredentials':true,
                    'sameSite': 'None',
                    "Cache-Control": "no-cache",
                    "crossDomain": true,

                },
                withCredentials: true
            });

            if (response.status == 200) {
                const loginStatusObject = response.data.authentication;
                const apiKey = loginStatusObject.api_key;
                const apiSecret = loginStatusObject.api_secret;

                Cookies.set("token", apiKey + ":" + apiSecret);
                window.location.href = "/home";
                setLoading(false);
                // const setCookieHeader = response.headers['Set-Cookie'];    

                // if (setCookieHeader && setCookieHeader.length > 0) {
                //   const cookie = setCookieHeader[0];
                //   console.log('Response Cookie:', cookie);
                //   const cookieValue = cookie.split(';')[0].split('=')[1];
                //   console.log('Cookie Value:', cookieValue);
                // }


            }

            else {
                setLoading(false);
                setSubtitleDialog("An Error Occurred");
                openErrorDialog();
                handleLoginFailure();
            }
        } catch (error) {
            setLoading(false);
            setSubtitleDialog("An Error Occurred");
            openErrorDialog();
            handleLoginFailure();
            console.log(error);
        }
    }

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };


    return (
      <div className="login">
        <div className=" back login-container">
          <div className="container wrapper">
            <div className="sign-div">
              <div className="sign-title">LOG IN</div>
              <div className="sign-subtitle">
                dolor sit amet, consectetur adipiscing elit. Vestibulum nisl leo
                consectetur .
              </div>
              <div>
                <div className="dashed-line"></div>

                <div
                  className="form-group margin-input"
                  style={{ position: "relative" }}
                >
                  <div className="sign-icon-div">
                    <UsernameSvg />
                  </div>
                  <input
                    type="text"
                    className="form-control log-input is-valid"
                    id="Username"
                    placeholder="Username"
                    name="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  {usernameError && (
                    <div className="invalid-feedback">
                      Username is required.
                    </div>
                  )}
                </div>

                <div className="form-group margin-input" style={{ position: "relative" }} >
                  <div className="sign-icon-div"  >
                  <PasswordSvg />
                  </div>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control log-input is-valid"
                    id="Password"
                    placeholder="Password"
                    name="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordError && (
                    <div className="invalid-feedback">
                      Password is required.
                    </div>
                  )}

                  <div className='faEye-icon' onClick={togglePasswordVisibility} >
                  {showPassword ? (
                      <FontAwesomeIcon icon={faEye} />
                    ) : (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      
                    )}
                  </div>
                
                </div>
                <div className="display-row">
                  <div className="forget-text" style={{ textAlign: "left" }}>
                    {" "}
                    Forgot your password?{" "}
                  </div>
                  <div
                    className="flex align-items-center"
                    style={{ display: "flex" }}
                  >
                    <Checkbox
                      inputId="remember"
                      name="remember1"
                      value="Remember me"
                      onChange={handleCheckboxChange}
                      checked={isChecked}
                    />
                    <label
                      htmlFor="remember"
                      className="ml-2 forget-text"
                      style={{ paddingLeft: "5px" }}
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <div className="dashed-line"></div>
                <button
                  style={{ height: "45px" }}
                  disabled={loading}
                  className="sign-button"
                  onClick={handleLogin}
                >
                  {loading == true ? (
                    <CircularProgress size={24} />
                  ) : (
                    <div>Login</div>
                  )}
                </button>

                <button
                  className="sign-button"
                  style={{
                    color: "#101728",
                    backgroundColor: "white",
                    border: "1px solid #CFCFCF",
                  }}
                >
                  <div className="display-google-button">
                    <GoogleSvg />
                    <div style={{ paddingLeft: "6px" }}></div>
                    Sign in with Google
                  </div>
                </button>
                <div className="account-text">
                  Dont have an account?{" "}
                  <span>
                    {" "}
                    <a href="/register"> Sign up now </a>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="login-back">
            <div className=""></div>
            <div></div>
          </div>
          <Dialog
            open={errorDialogOpen}
            onClose={() => {
              setErrorDialogOpen(false);
            }}
          >
            {/* <DialogTitle>
        <Typography variant="h4">Error</Typography>
      </DialogTitle> */}
            <DialogContent>
              <Typography variant="h6">Sorry</Typography>
              <Typography variant="subtitle2">{subtitledialdg}</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                style={{ backgroundColor: "#101728" }}
                variant="contained"
                onClick={(e) => {
                  setErrorDialogOpen(false);
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>{" "}
      </div>
    );
}


export default LoginPage;