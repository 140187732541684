import React, { useState, useEffect } from "react";
import "./Card.css";
import { ReactComponent as EditIcon } from "../../asset/images/svg/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../asset/images/svg/close-icon.svg";
import { Row } from "react-bootstrap";
import {Link} from 'react-router-dom';
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';
import { isStepDivisible } from "react-range/lib/utils";

const ShoppingCard = ({ title,webItemId, parentWebItemName,parentWebItemId, description, brand, image, qty, attributes,isVarient, amount, currency, onDelete,onChangeQty }) => {
  const [quantity, setQuantity] = useState(qty);
  const navigate = useNavigate();
  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };
  useEffect(() => {
    if(quantity!= qty){
      onChangeQty(quantity);
    }
}, [quantity]);

  return (
    <div className="card-box">
      <Row>
        <div className="col-md-3 col-lg-3 ">
          <img src={image} alt="My SVG" className="img-fit " />
        </div>
        <div className="col-md-6 col-lg-6">
          <div className="item-container">
            <div className="item-title ">{title}</div>
            <div className="item-text">
              <p dangerouslySetInnerHTML={{ __html: description }} />

            </div>
            <div className="item-text">
              <span className="item-attribute">Brand</span>: {brand}
            </div>

            {attributes.length === 0 ? null : (
              <div >
                <div className="item-sub-title">Item details</div>
                <div className="divider-cart"></div>
                {attributes.map((attribute, index) => (
                  <div>
                    <Row>
                      <div className="col-md-6 col-6" style={{ paddingLeft: "0px" }}>
                        <div className="item-attribute">{attribute.attribute}</div>
                      </div>
                      <div className="col-md-6 col-6">
                        <div className="item-details-text">{attribute.attribute_value}</div>
                      </div>
                    </Row>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-3 col-lg-3">
          <div className="price">{amount} {currency}</div>
          <div className="item-text">This price without TAX and Delivery</div>
          <div className="quantity-text">QUATINTY</div>

          <div className="input-group">
            <button className="button-minus" onClick={handleDecrement}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="2"
                viewBox="0 0 12 2"
                fill="none"
              >
                <path
                  d="M1 1H11"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <input
              type="number"
              step="1"
              max=""
              value={quantity}
              name="quantity"
              className="quantity-field"
              readOnly
            />

            <button className="button-plus" onClick={handleIncrement}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M6.13221 1.5556L6.11914 11.5556"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.11084 6.5556H11.1108"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </Row>
      <div className="btn-div">

        {/* <Link   to= { "/product/WEB-ITM-0023"}
          state= {{ quantity: qty, varient_id: title}}  //missing me parent_id item_code and item_name
          reloadDocument= {false}
       > */}
          <div className="edit-div" style={{ cursor: 'pointer' }} onClick={()=>    localStorage.setItem('myState', JSON.stringify({ qty, title }), window.location.href= isVarient==true?'/product/'+ parentWebItemId:"/product/"+ webItemId)}>
          <EditIcon className="svg-margin" />
        </div>
          
          {/* </Link> */}
        <div className="delete-div" onClick={() => onDelete()} style={{ cursor: 'pointer' }}>
          <DeleteIcon className="svg-margin" />
        </div>
      </div>
    </div>
  );
};

export default ShoppingCard;
