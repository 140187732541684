import React from 'react';
import "./SplitInFour.css"
import { Container } from "react-bootstrap";
// import { Status } from "../../../../components"
// import statusone from "../../../../asset/images/svg/part1.svg"
// import statustwo from "../../../../asset/images/svg/part2.svg"
// import statusthree from "../../../../asset/images/svg/part3.svg"
// import statusfour from "../../../../asset/images/svg/part4.svg"

const SplitInFour = ({ contentData, url }) => {

  return (
      <Container className="splitfour">
        <h1>{contentData.split_in_title?? "Client Reviews"}</h1>
        <p>{contentData.split_in_description?? "Split in 4 interest-free installments"}</p>
         <img  alt="splitImage" style={{width:"100%"}} src={contentData.split_in_image
            ? url + contentData.split_in_image
            :require("../../../../asset/images/Home/splitInFour.png")} />

        {/* <Row className="four">
            <Status
              showTime={'1300'}
              image={statusone}
              num={'25%'}
              day={'Today'}
            />
            <Status
              showTime={'1500'}
              image={statustwo}
              num={'25%'}
              day={'In 1 month'}
            />
            <Status
              showTime={'1800'}
              image={statusthree}
              num={'25%'}
              day={'In 2 months'}
            />
            <Status
              showTime={'2100'}
              image={statusfour}
              num={'25%'}
              day={'In 3 months'}
              finalStatus={true}
            />
        </Row>  */}
      </Container>
  );
};

export default SplitInFour;