import "./SuccessVerify.css"
import React from 'react'
import { ReactComponent as CheckSvg } from '../../../../asset/images/svg/check.svg';

const SuccessVerify = ({RegisterEmail}) => {

  return (

    <div className='verification-container'>
      <div className="back-img">

        <div className="ver-content">
          <div className="check-div">
            <CheckSvg />
          </div>
          <div className="ver-title">
            Email verified
          </div>
          <div className="ver-subtitle">
            We sent a verification code to <br /> {RegisterEmail}
          </div>
          <button className="ver-button margin-check-button"  onClick={() => window.location.href = '/resetPassword'}>Continue</button>
        </div>
      </div>

    </div>
  );
};

export default SuccessVerify;


