import React from 'react';
import "./Timer.css";
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import '@leenguyen/react-flip-clock-countdown/dist/index.css';

const Timer = ({ time ,onComplete}) => {

    return (
        <div>
            <FlipClockCountdown to={time} hideOnComplete={true}  onComplete={onComplete}>
            </FlipClockCountdown>

        </div>
    );
}


export default Timer;

