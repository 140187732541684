import React from 'react'
import "./Hero.css"
import { Col, Container, Row } from 'react-bootstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import { Timer } from '../../../../components';

const Hero = ({ contentData, url }) => {
  // const heroList = contentData.hero_section_image_1 || [];


  return (
    <div className="hero">
      {/* <img alt="hero-bottom" src={require("../../../../asset/images/Home/hero-v1.png")} id="hero-bottom" />
      <img alt="hero-center" src={require("../../../../asset/images/Home/home-v.png")} id="hero-center" />
      <img alt="hero-left" src={require("../../../../asset/images/Home/Mi Element 1 1.png")} id="hero-left" /> */}
      <Splide
        options={{
          type: "loop",
          perPage: 1,
          perMove: 1,
          arrows: false,
          pagination: true,
        }}
      >
        {/* <SplideSlide>
            <Container className='hero-container'>
              <Row>
                <Col lg={6}>
                  <div className='hero-content' data-aos="fade-right" data-aos-duration="1500">
                    <h1 className='hero-content-title'>Children's <span>Products</span></h1>
                    <p className='hero-content-para'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate,</p>
                    <Timer />
                    <a href='#' className='hero-content-link'>Shop Now</a>
                  </div>
                  <div className='hero-sale' data-aos="fade-up" data-aos-duration="1500">
                    <h1 className='hero-sale-title'><span>30 %</span> GET OFF</h1>
                    <p className='hero-sale-para'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, </p>
                  </div>
                </Col>
                <img className='hero-image' src={require("../../../../asset/images/Home/heroimage.png")} />
              
              </Row>
            </Container>
          </SplideSlide>
          <SplideSlide>
            <Container className='hero-container'>
              <Row>
                <Col lg={6}>
                  <div className='hero-content' data-aos="fade-right" data-aos-duration="1500">
                    <h1 className='hero-content-title'>Children's <span>Products</span></h1>
                    <p className='hero-content-para'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate,</p>
                    <a href='#' className='hero-content-link'>Shop Now</a>
                  </div>
                  <div className='hero-sale' data-aos="fade-up" data-aos-duration="1500">
                    <h1 className='hero-sale-title'><span>30 %</span> GET OFF</h1>
                    <p className='hero-sale-para'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, </p>
                  </div>
                </Col>
                  <img className='hero-image' src={require("../../../../asset/images/Home/heroimage.png")} />
              </Row>
            </Container>
          </SplideSlide> */}

        {/* {heroList.map((heroItem, index) => ( */}
        <SplideSlide>
          <div className="hero-container">
            <div className="hero-title">
              Children's <span style={{ fontWeight: "800" }}>Products</span>
            </div>
            <div className="hero-sub-title">The best way for kids</div>
            <button className="hero-btn">Shop Now</button>
          </div>
        </SplideSlide>

        <SplideSlide>
          <div className="hero-container">
            <div className="hero-title">
              Children's <span style={{ fontWeight: "800" }}>Toys</span>
            </div>
            <div className="hero-sub-title">Find what they love</div>
            <button className="hero-btn">Shop Now</button>
          </div>
        </SplideSlide>

        {/* // ))} */}

        {/* <Container className='hero-container'>
              <Row>
                <Col lg={6}>
                  <div className='hero-content' data-aos="fade-right" data-aos-duration="1500">
                    <h1 className='hero-content-title'>Children's <span>Products</span></h1>
                    <p className='hero-content-title' dangerouslySetInnerHTML={{ __html: heroItem.hero_section_title }} />

                    <p className='hero-content-para'>{heroItem.hero_section_description ?? "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate,"}</p>
                    {index === 0 && <Timer time={contentData.hero_section_time} />}
                    <a href='' className='hero-content-link'>Shop Now</a>
                  </div>
                  <div className='hero-sale' data-aos="fade-up" data-aos-duration="1500">
                    <h1 className='hero-sale-title'><span>30 %</span> GET OFF</h1>
                    <p className='hero-sale-para'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, </p>
                    <p className='hero-sale-para' dangerouslySetInnerHTML={{ __html: heroItem.hero_section_description_part_2 }} />

                  </div>
                </Col>
                <img alt="hero" className='hero-image'
                  src={heroItem.image
                    ? url + heroItem.image
                    : require("../../../../asset/images/Home/heroimage.png")}
                />
              </Row>
            </Container> */}
      </Splide>
    </div>
  );
}

export default Hero;