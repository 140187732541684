import React from 'react'
import "./ProductsCategory.css"
import { Col, Row } from 'react-bootstrap'

const ProductsCategory = ({ title, image, color, href, handleClick }) => {
  const handleLinkClick = (event) => {
    if (handleClick) {
      event.preventDefault(); // Prevent the default link behavior
      handleClick(); // Call the click handler
    }
  };

  return (
    <a href={href} onClick={handleLinkClick}>
      <div className="products-category" style={{ backgroundColor: color }}>
        <Col lg={6}>
          <div className="products-category-title">{title}</div>
        </Col>
        <Col lg={6} className="products-category-img">
          <div className="image-container">
            <img src={image} alt="Product" className="image" />
          </div>
        </Col>
      </div>
    </a>
  );
};

export default ProductsCategory