import React from 'react'
import "./Category.css"
import { Col } from 'react-bootstrap'

const Category = ({ title, para, image, color , href, handleClick}) => {
    const handleLinkClick = (event) => {
        if (handleClick) {
          event.preventDefault(); // Prevent the default link behavior
          handleClick(); // Call the click handler
        }
      };
    return (
        
        <Col lg={3} md={6} className="category" data-aos="fade-left" data-aos-duration="1500">
            <a href={href} onClick={handleLinkClick}>
            <div className="category-img">
                <img src={image} />
                <img className="color" src={color} />
            </div>
            <div className="category-content">
                <h1>{title}</h1>
                <p>{para}</p>
            </div>
            </a>
        </Col>
    
    )
}

export default Category
