import React from "react";
import "./ProductBanner.css"
import { Container, Row } from "react-bootstrap";
import { Product } from "../../../../components";

const ProductBanner = ({ contentData, url }) => {

    return (
      <Container className="products-card">
        <Row>
          <Product
            title={"School furniture"}
            para={contentData.category_offer_1_description}
            mainColor={false}
            image = {contentData.category_offer_1_image
              ? url + contentData.category_offer_1_image
              : require("../../../../asset/images/Home/banner1.png")
              } 
          />
          <Product
            title={"Trendy Toys"}
            para={contentData.category_offer_2_description}
                        mainColor={true}
            image = {contentData.category_offer_2_image
            ? url + contentData.category_offer_2_image
            : require("../../../../asset/images/Home/banner2.png")
            } 
          />
        </Row>
    </Container>
    );
  };
  
  export default ProductBanner;


  