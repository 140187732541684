import React from 'react'
import "./ProductReviewClient.css"
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';

const ProductReviewClient = ({ name, para, image, rate, time }) => {
    return (
        <div class="product-review">
            <div className='detail'>
                <Avatar alt="profile" src={image} />
                <span className='reviwer'>{name}</span>
                <div className='time'>.{time}</div>
                <div className='d-flex' style={{ alignItems: "center" }}>
                    <Rating readOnly={true} value={rate} size="small" />
                    <div className='rate'>{rate}</div>
                </div>
            </div>
            <div className='para'>{para}</div>



        </div>
    )
}

export default ProductReviewClient
