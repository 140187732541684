import React, { useState, useEffect } from "react";
import {
  Navbar,
  Container,
  NavDropdown,
  Nav,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import "./NavPage.css";
import logo from "../../asset/images/svg/logo-navbar-new.svg";
import man from "../../asset/images/svg/account-icon.svg";
import basket from "../../asset/images/svg/basket2.svg";
import coins from "../../asset/images/svg/coins.svg";
import en from "../../asset/images/svg/lang-icon.svg";
import saudi from "../../asset/images/svg/saudi.svg";
import oman from "../../asset/images/svg/oman.svg";
import logout from "../../asset/images/svg/Logout.svg";
import axios from "axios";
import Cookies from "js-cookie";

const NavPage = ({ url }) => {
  const [scrolled, setScrolled] = useState(false);
  const [lang, setLang] = useState("en"); 
  const [selectedCountry, setSelectedCountry] = useState("saudi"); 
  const [categories, setCategories] = useState([]);
  // const heroList = contentData.hero_section_image_1 || [];


  const fetchCategories = async () => {
    try {
        const response = await axios.get(`${url}./api/method/geniusapi.www.api.categories`);
        setCategories(response.data.result);
    } catch (error) {
        console.error("Error fetching categories:", error);
    }
};

  useEffect(() => {
      fetchCategories();
  }, []);


  const toggleLanguage = () => {
    setLang((prevLang) => (prevLang === "en" ? "ar" : "en"));
  };




  const toggleCountry = () => {
    setSelectedCountry((prevCountry) => (prevCountry === "saudi" ? "oman" : "saudi"));
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0 && !scrolled) {
        setScrolled(true);
      } else if (scrollTop === 0 && scrolled) {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  const handleLogout = async () => {
    try {
      const response = await axios.post(url + "/api/method/logout", {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
      });

      if (response.status == 200) {
        Cookies.remove("token");
        window.location.reload();
      }
    } catch (error) {}
  };
  return (
  
      <Navbar
        expand="lg"
        className={`navbar ${scrolled ? "navbar-scroll" : ""}`}
      >
        <Container className="nav">
          <Navbar.Brand href="#">
            <a className="navbar-brand" href="/home">
              <img src={logo} />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <div div className="responsive-nav">
              <div className={`up-nav ${scrolled ? "up-nav-scroll" : ""}`}>
                <div className="searching">
                  <NavDropdown
                    className={`${scrolled ? "dropdown-scroll" : ""}`}
                    title="All Categories"
                    id="basic-nav-dropdown"
                  >
                    {categories.map((category, index) => (
                      <NavDropdown.Item key={index} href={`#${category.name}`}>
                        {category.name}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                  <input
                    type="text"
                    placeholder="Search experts, products or brands"
                    className={` ${scrolled ? "searching-input-scroll" : ""}`}
                  />
                  <button
                    id="search"
                    className={` ${scrolled ? "btn-search-scroll" : ""}`}
                  >
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
                <div
                  className={`settings ${scrolled ? "settings-scroll" : ""}`}
                >
                  <div className="settings-content" onClick={toggleLanguage}>
                    <img src={en} />
                    {/* <h1>EN</h1> */}
                  </div>
                  <div className="settings-seperator"></div>
                  {Cookies.get("token") === null ||
                  Cookies.get("token") === "" ||
                  Cookies.get("token") === undefined ? (
                    <div className="settings-content">
                      <img src={man} />
                      <a href="/login">{/* <h1>Sign In</h1> */}</a>
                    </div>
                  ) : (
                    <div className="settings-content">
                      <img src={logout} />
                      <a onClick={handleLogout}>{/* <h1>Sign Out</h1> */}</a>
                    </div>
                  )}
                  <div className="settings-seperator"></div>
                  {/* {Cookies.get("token") !== null &&
                Cookies.get("token") !== "" &&
                Cookies.get("token") != undefined ? ( */}
                  <div className="country" onClick={toggleCountry}>
                    <div className="country-content">
                      <img
                        src={selectedCountry === "saudi" ? saudi : oman}
                        alt={
                          selectedCountry === "saudi" ? "Saudi Arabia" : "Oman"
                        }
                      />
                      {/* <h1 style={{color:"#000;"}}>{selectedCountry === "saudi" ? "Saudi Arabia" : "Oman"}</h1> */}
                    </div>
                    {/* <div className="country-content">
                    <img src={coins} />
                    {/* <h1>USD</h1> 
                  </div> */}
                  </div>
                  <div className="settings-seperator"></div>
                  <div className="settings-content">
                    <img
                      src={basket}
                      onClick={() => (window.location.href = "/shoppingCart")}
                    />
                    <div className="basket-number">5</div>
                  </div>
                  {/* // ) : null} */}
                </div>
              </div>
              <div className={`down-nav ${scrolled ? "down-nav-scroll" : ""}`}>
                <div className="links">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a className="nav-link active" href="/home">
                        Home
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" href="/categories">
                        Categories
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        interior design
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
};

export default NavPage;
