import React, { useState, useEffect } from "react";
import "./CategoriesPage.css";
import { Filters } from "../../Features/ProductsPage";

import { ProductsCategory, Loading } from "../../components/index";
import { Container, Row } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

export const CategoriesPage = ({
  resultData,
  url,
  apiMethod,
}) => {

  const [categoriesData, setCategoriesData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [loading, setLoading] = React.useState(true);


  useEffect(() => {
    fetchDataByPage();
  }, []);


  useEffect(() => {
    fetchDataByPage();
  }, [selectedBrands]);

  useEffect(() => {
    fetchDataByPage();
  }, [searchTerm]);




  const fetchDataByPage = async () => {
    const searchParam = searchTerm ? `&search=${searchTerm}` : "";

    var brandsParam = "";
    if (selectedBrands.length > 0) {
      brandsParam = "&brands=";
      brandsParam = brandsParam + selectedBrands.join(",");
    }

    const apiURL = `${url}${apiMethod}.content_by_page_name?name=categories${searchParam}${brandsParam}`;
    try {
      const response = await axios.get(apiURL);
      setLoading(false)
      setBrands(response?.data?.result?.brands);
      const data = response?.data?.result?.categories;
      // const pagination = response?.data?.result?.items?.pagination;

      setCategoriesData(data);
    } catch (error) {
      console.log("Error fetching categories:", error);
    }
  };


  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
  };


  const handleChangeBrand = (e) => {
    const brandName = e.target.value;
    const isChecked = e.target.checked;
    const myNextList = [...selectedBrands];
    if (isChecked) {
      if (!selectedBrands.includes(brandName)) {
        myNextList.push(brandName);
      }
    } else {
      const index = myNextList.indexOf(brandName);
      if (index !== -1) {
        myNextList.splice(index, 1);
      }
    }
    setSelectedBrands(myNextList);
  };


  const navigate = useNavigate();

  const handleClick = (category) => {
    if (category.is_group === 0) {
      const categoryName = category.name;
      // navigate(`/products?categories=${categoryName}`);
      window.location.href = `/products?categories=${categoryName}`;
    }
    else {
      window.location.href = '/category/' + category.name;
    }
  };

  return (
    <div>

      {loading == true ?
        (
          <Loading></Loading>
        )
        :
        <div className="products">
          <Container>
            <Row>
              <div
                className="col-md-4 col-lg-3 col-12"
                style={{ paddingLeft: "0px !important" }}
              >
                <Filters
                  resultData={resultData}
                  url={url}
                  onSearch={handleSearch}
                  BrandsFilter={brands}
                  onChangeBrand={handleChangeBrand}
                  isCategory={true}
                />
              </div>
              <div className="col-md-8 col-lg-9 col-12 ">

                {categoriesData.length === 0 ? (
                  <center>  <p>No Categories</p> </center>

                ) : (
                  <Row>
                    {categoriesData.map((category, index) => (
                      <div className="col-md-6 col-lg-4 col-12 categories-col-sm">
                        <ProductsCategory
                          title={category.name}
                          image={
                            category.image
                              ? url + category.image
                              : require("../../asset/images/Categories/category3.png")
                          }
                          color={index % 2 == 0 ? "#bca186" : "#101728"}
                          handleClick={() => handleClick(category)}
                          href={category.is_group === 0 ? '/products' : null}
                        ></ProductsCategory>
                      </div>
                    ))}
                  </Row>
                )}
                <div
                  className="d-flex justify-content-center"
                  style={{ marginTop: "30px" }}
                >
                </div>
              </div>
            </Row>
          </Container>
        </div>}
    </div>

  );
};

export default CategoriesPage;
