import React, { useState, useEffect, useRef } from 'react';
import "./ProductsPage.css"
import { CategoriesSlider, Filters } from '../../Features/ProductsPage'
import { Card, Paginations, Loading } from '../../components/index';
import { Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

export const ProductsPage = ({ url, limit, apiMethod }) => {
    const location = useLocation();
    const categoryParam = new URLSearchParams(location.search).get('categories') ?? null;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    const [productsData, setProductsData] = useState([]);
    const [categoriesGlobal, setCategoriesGlobal] = useState([]);
    const [brandsGlobal, setBrandGlobal] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(10000);
    const [selectedCategories, setCategories] = useState([new URLSearchParams(location.search).get('categories')]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [categoryParams, setCategoryParams] = useState([]); // this get when pressed on categoryCard in category page
    const prevSelectedBrandsRef = useRef([]);
    const prevSearchTermRef = useRef("");
    const prevSelectedCategoriesRef = useRef([]);
    const prevMinPriceRef = useRef(0);
    const prevMaxPriceRef = useRef(10000);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        fetchDataByPage(currentPage);
    }, []);


    useEffect(() => {
        if (categoryParam) {
            setCategoryParams(categoryParam);
        }
    }, [categoryParam]);


    useEffect(() => {
        fetchDataByPage(currentPage);
    }, []);


    useEffect(() => {

        if (selectedBrands && selectedBrands !== prevSelectedBrandsRef.current) {
            fetchDataByPage(currentPage);
            prevSelectedBrandsRef.current = selectedBrands;
        }
    }, [selectedBrands]);

    useEffect(() => {

        if (searchTerm && searchTerm !== prevSearchTermRef.current) {
            fetchDataByPage(currentPage);
            prevSearchTermRef.current = searchTerm;
        }
    }, [searchTerm]);

    useEffect(() => {

        if (selectedCategories && selectedCategories !== prevSelectedCategoriesRef.current) {
            fetchDataByPage(currentPage);
            prevSelectedCategoriesRef.current = selectedCategories;
        }
    }, [selectedCategories]);

    useEffect(() => {

        if ((minPrice || maxPrice) && (minPrice !== prevMinPriceRef.current || maxPrice !== prevMaxPriceRef.current)) {
            fetchDataByPage(currentPage);
            prevMinPriceRef.current = minPrice;
            prevMaxPriceRef.current = maxPrice;
        }
    }, [minPrice, maxPrice]);



    const fetchDataByPage = async (page) => {

        const startIndex = (page - 1) * limit;
        const searchParam = searchTerm ? `&search=${searchTerm}` : '';
        const minPriceParam = minPrice ? `&min_price=${minPrice}` : '';
        const maxPriceParam = maxPrice ? `&max_price=${maxPrice}` : '';
        var categoriesParam = "";
        if (selectedCategories.length > 0) {
            categoriesParam = "&item_groups=";
            categoriesParam = categoriesParam + selectedCategories.join(',');
        }
        var brandsParam = "";
        if (selectedBrands.length > 0) {
            brandsParam = "&brands=";
            brandsParam = brandsParam + selectedBrands.join(',');
        }


        const apiURL = `${url}${apiMethod}.content_by_page_name?name=products&start=${startIndex}&limit=${limit}${searchParam}${minPriceParam}${maxPriceParam}${categoriesParam}${brandsParam}`;
        try {

            const response = await axios.get(apiURL);
            setLoading(false);
            setCategoriesGlobal(response?.data?.result?.categories);
            setBrandGlobal(response?.data?.result?.brands);
            const data = response?.data?.result?.items?.items;
            const pagination = response?.data?.result?.items?.pagination;

            setProductsData(data);
            setTotalPages(pagination?.metadata?.total_pages);
            setTotalProducts(pagination?.metadata?.total_data);

        } catch (error) {
            console.log('Error fetching products:', error);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchDataByPage(page);
    };
    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);
    };
    const handlePrice = (minPrice, maxPrice) => {
        setMinPrice(minPrice);
        setMaxPrice(maxPrice);
    };



    const handleChangeBrand = (e) => {
        const brandName = e.target.value;
        const isChecked = e.target.checked;
        const myNextList = [...selectedBrands];
        if (isChecked) {
            if (!selectedBrands.includes(brandName)) {
                myNextList.push(brandName);
            }
        } else {
            const index = myNextList.indexOf(brandName);
            if (index !== -1) {
                myNextList.splice(index, 1);
            }
        }
        setSelectedBrands(myNextList);
    };


    const handleChangeCategory = (value, checked) => {
        const categoryName = value;
        const isChecked = checked;
        const myNextList = [...selectedCategories];
        if (isChecked) {
            if (!selectedCategories.includes(categoryName)) {
                myNextList.push(categoryName);
            }
        } else {
            const index = myNextList.indexOf(categoryName);
            if (index !== -1) {
                myNextList.splice(index, 1);
            }
        }
        setCategories(myNextList);
    };

    const handleChangeCategory2 = (values, checked) => {
        const categoryNames = values;
        const isChecked = checked;
        const myNextList = [...selectedCategories];
        if (isChecked) {
            categoryNames.forEach((name) => {
                if (!selectedCategories.includes(name)) {
                    myNextList.push(name);
                }
            })

        } else {

            categoryNames.forEach((name) => {
                const index = myNextList.indexOf(name);
                if (index !== -1) {
                    myNextList.splice(index, 1);
                }
            })
        }
        setCategories(myNextList);
    };



    return (
        <div>

            {loading == true ?
                (
                    <Loading></Loading>
                )
                :
                <div className='products'>
                    <Container>
                        <Row>
                            <div className='col-md-4 col-lg-3 col-12' style={{ paddingLeft: "0px !important" }}>
                                <Filters url={url}
                                    onSearch={handleSearch} onPriceFilter={handlePrice}
                                    CategoriesFilter={categoriesGlobal}
                                    onCategoryChange={handleChangeCategory}
                                    onCategoryChange1={handleChangeCategory2}
                                    BrandsFilter={brandsGlobal}
                                    onChangeBrand={handleChangeBrand}
                                    categoryParams={selectedCategories}
                                />
                            </div>
                            <div className='col-md-8 col-lg-9 col-12'>
                                {categoryParam ? (
                                    <div className="banner">
                                        <img
                                            src={require("../../asset/images/Products/banner3.jpg")}
                                            alt="Banner"
                                            className="banner-image"
                                        />
                                    </div>
                                ) : (
                                    <CategoriesSlider categories={categoriesGlobal} url={url} />

                                )}
                                <div className="products-list">
                                    {productsData.length === 0 ? (
                                        <center>  <p>No Products</p> </center>

                                    ) : (
                                        <div className="card-grid">
                                            {productsData.map((item, index) => (
                                                <Card
                                                    key={item.web_item_name}
                                                    title={item.item_group}
                                                    para={item.web_item_name}
                                                    image={url + item.website_image}
                                                    pricebefore={item.before_discount_price_list_rate}
                                                    priceafter={item.discounted_price_list_rate}
                                                    sale={item.sale}
                                                    name={item.name}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <div className="d-flex justify-content-center" style={{ marginTop: '30px' }}>
                                    <Paginations currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                                </div>

                                {totalProducts !== 0 && (
                                    <div className='total'>
                                        Total: {totalProducts}
                                    </div>
                                )}
                            </div>
                        </Row>

                    </Container>
                </div>
            }</div>
    )
}


export default ProductsPage;