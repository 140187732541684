import React from 'react';
import "./Home.css";
import { Hero, Categories, Product, TrendyProduct, SpecialOffer, ProductBanner, TabBay, SplitInFour, ClientReviews } from '../../Features/Home/index';

export const Home = ({ contentData, innerPageData, itemsList, categoryList, url, apiMethod }) => {
    return (
        <div className='home'>
            <a href="https://wa.me/YOUR_PHONE_NUMBER" className="whatsapp-float" target="_blank" rel="noopener noreferrer">
                <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" alt="WhatsApp" className="whatsapp-icon" />
            </a>
            <Hero contentData={contentData} url={url} />
            <Product contentData={contentData} itemsList={itemsList} categoryList={categoryList} url={url} apiMethod={apiMethod} />
            <Categories contentData={contentData} categoryList={categoryList} url={url} />
            <TrendyProduct contentData={contentData} url={url} />
            <SpecialOffer contentData={contentData} itemList={itemsList} url={url} />
            <ProductBanner contentData={contentData} url={url} />
            <TabBay contentData={contentData} url={url} />
            <SplitInFour contentData={contentData} url={url} />
            <ClientReviews contentData={contentData} url={url} />
        </div>
    );
}

export default Home;
