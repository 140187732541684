import React , { useState ,useEffect} from 'react'
import "./CheckEmailPage.css"
import { CheckEmail} from '../../Features/VerificationPage/index'
import { useLocation } from 'react-router-dom';


export const CheckEmailPage = () => {
  const location = useLocation();
  const [registeredEmail, setRegisteredEmail] = useState('');

  useEffect(() => {
    // If using local storage
    const userEmail = localStorage.getItem('registeredEmail');

    // // If using query parameters
    // const searchParams = new URLSearchParams(location.search);
    // const userEmail = searchParams.get('email');

    if (userEmail) {
      setRegisteredEmail(userEmail);
    }
  }, []);
;

  return (
    <div className='check-email'>
     <CheckEmail RegisterEmail={registeredEmail}/>
    </div>
  );
};
export default CheckEmailPage;