import React from 'react';
import "./TrendyProduct.css"
import { Col, Container, Row } from "react-bootstrap";


const TrendyProduct = ({ contentData, url }) => {
  
    return (
      <div className="trendyProduct">
        <Container>
          <Row>
            <Col lg={6} className="trendyProduct-content">
          
               {contentData.trendy_description && (
                <p
                data-aos="fade-right"
                data-aos-duration="1800"
                dangerouslySetInnerHTML={{ __html: contentData.trendy_description }}
              ></p>
              )}
              {!contentData.trendy_description && (
                <>
                  <h4 data-aos="fade-right" data-aos-duration="1300">Sale <span>20%</span> for all products</h4>
                  <h1 data-aos="fade-right" data-aos-duration="1500">Trendy products</h1>
                  <p data-aos="fade-right" data-aos-duration="1800">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate, sum dolor sit amet, cons ipsum dolor sit amet, consec</p>
                </>
              )}
             <a data-aos="fade-right" data-aos-duration="2000">SHOP NOW</a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
  
  export default TrendyProduct;