import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import SwiperCore from "swiper";
import "./ImageProductSlider.css"

import { Navigation, Thumbs,Pagination } from "swiper/modules";

SwiperCore.use([Navigation, Thumbs,Pagination]);



const ImageProductSlider = ({ resultData, url,height }) => {
    const gallery = resultData || [];

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const swiperRef = useRef(null);

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        swiperRef.current.swiper.slideTo(index);
    };
    return (
<div className="d-flex" style={{ height: height ? "550px" : "0px" }}>
            <div className="first-show-swipper first-swapper">
                <Swiper className=" image-product"
                    style={{
                        '--swiper-navigation-color': '#fff',
                        '--swiper-pagination-color': '#fff',
                        height: "100%"
                    }}
                    spaceBetween={10}
                    navigation={true}
                    thumbs={{ swiper: thumbsSwiper }}
                    onSlideChange={(swiper) => setSelectedImageIndex(swiper.activeIndex)}
                    pagination={{
                        clickable: true,
                        renderBullet: function (index, className) {
                          if (index < 4) {
                            return '<span class="' + className + '">' +  "</span>";
                          }  else {
                            return '';
                          }
                        },
                      }}
                >
                    {gallery.map((image, index) => (
                        <SwiperSlide><img src={url + image.link
                        } /></SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <div className="second-show-swipper">
                <Swiper
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    direction="vertical"
                    ref={swiperRef}
                >
                    {gallery.map((image, index) => (
                        <SwiperSlide><img src={url + image.link}
                            onClick={() => handleImageClick(index)}
                            className={selectedImageIndex === index ? "selected-image" : "other-image"}

                        /></SwiperSlide>
                    ))}
                </Swiper>


            </div>



        </div>
    )
}
export default ImageProductSlider