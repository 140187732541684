import React from 'react'
import "./Product.css"
import { Col } from "react-bootstrap"

const Product = ({ title, para, image, mainColor }) => {
    return (
        <Col lg={6} className="product-card" data-aos="fade-right" data-aos-duration="2000">
            <div className="row card-responsive">
            <div className="col-6 product-card-content">
                <div className="product-card-text" id={`${mainColor ? 'main-color' : 'secondary-color'}`}>
                {/* <h1>{title}</h1> */}
                <p className="product-para"   dangerouslySetInnerHTML={{ __html: para }}>
                    </p>
                <div className="d-flex">
                    <a>Show Products</a><i className="fa-solid fa-arrow-right-long"></i>
                </div>
                </div>  
            </div>
            <Col sm={6} xs={6} className="product-card-img">
                <img src={image} />
            </Col>
            </div>
        </Col>
    )
}

export default Product
