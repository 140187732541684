import React, { useState, useEffect } from 'react'
import "./PriceRange.css"
import { Range } from 'react-range';

const PriceRange = ({ minPrice, maxPrice, handlePrice }) => {
  const [rangeValues, setValues] = useState([0, 10000]);


  useEffect(() => {
    handlePrice(rangeValues[0], rangeValues[1]);
  }, [rangeValues]); //handlePrice

  // const handleChange = (newValues) => {
  //   setValues(newValues);
  // };

  const handleMinChange = (event) => {
    const newMinValue = parseInt(event.target.value);
    setValues([newMinValue, rangeValues[1]]);
  };

  const handleChange = (newValues) => {
    setValues(newValues);
    handlePrice(newValues[0], newValues[1]);
  };
  //   const handleMinChange = (event) => {
  //     const newMinValue = parseInt(event.target.value);
  //     console.log(newMinValue)
  //     setValues([newMinValue, rangeValues[1]]);
  //     console.log(rangeValues[0])
  //     handlePrice( rangeValues[0], rangeValues[1]);
  //   };

  const handleMaxChange = (event) => {
    const newMaxValue = parseInt(event.target.value);
    setValues([rangeValues[0], newMaxValue]);
    // handlePrice( rangeValues[0], rangeValues[1]);
  };

  return (
    <div >
      <Range
        values={rangeValues}
        step={100}
        min={minPrice}
        max={maxPrice}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '5px',
              width: '100%',
              backgroundColor: '#ccc',
              borderRadius: '4px',
   
            }}
          > {children}
                  <div
        className="range-fill"
        style={{
          height: '100%',
          borderRadius: '5px',
          background: `linear-gradient(to right, #ccc ${((rangeValues[0] - minPrice) / (maxPrice - minPrice)) * 100}%, #101728 ${((rangeValues[0] - minPrice) / (maxPrice - minPrice)) * 100}%, #101728 ${((rangeValues[1] - minPrice) / (maxPrice - minPrice)) * 100}%, #ccc ${((rangeValues[1] - minPrice) / (maxPrice - minPrice)) * 100}%)`,
        }}
      />

          </div>
        )}

        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '16px',
              width: '16px',
              backgroundColor: '#101728',
              borderRadius: '50%',
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              border: '2px solid rgb(255, 255, 255)',
              boxShadow: 'rgba(15, 16, 16, 0.55) 0px 2px 6px',
            }}
          />
        )}
      />
      <div className='input-div'>
        <input className='input-range' type="number" value={rangeValues[0]} onChange={handleMinChange} min={minPrice} max={rangeValues[1]} step="1000"/>
        <div className='sign-range'> - </div>
        <input className='input-range' type="number" value={rangeValues[1]} onChange={handleMaxChange} min={rangeValues[0]} max={maxPrice} step="1000" />
      </div>
    </div>
  )
}
export default PriceRange
