import "./Verification.css"
import React, { useState } from 'react';
import { ReactComponent as EmailSvg } from '../../../../asset/images/svg/email.svg';
import VerificationInput from "react-verification-input";

const Verification = ({RegisterEmail  }) => {
  const [verificationCode, setVerificationCode] = useState('');
 

  return (
    <div className='verification-container'>
      <div className="back-img">
    
        <div className="ver-content">
          <div className="email-div">
            <EmailSvg />
          </div>
          <div className="ver-title">
            check your email
          </div>
          <div className="ver-subtitle">
            We sent a verification code to <br /> {RegisterEmail }
          </div>

          <VerificationInput
            // value={verificationCode}
            onComplete={code => {setVerificationCode(code);
              localStorage.setItem('VerificationCode', code);

            }}
            
            inputField={{
              onChange: (e) => { 
                console.log(e.nativeEvent.target.value);
              }
            }}
            length={6}
            // validChars='A-Za-z0-9'
            // validChars=".*"
          />

          <button className="ver-button"  onClick={() => window.location.href = '/success'}>Verify Email</button>
          <div className="ver-text">
            Don’t receive the code? <span> Resend the code </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;