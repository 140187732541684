import React, { useState } from 'react';
import "./RegisterPage.css"
import { ReactComponent as GoogleSvg } from '../../asset/images/svg/google.svg';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios';
import {
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    Button,
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';


export const RegisterPage = ({ url }) => {


    const [firstName, setFirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [subtitledialdg, setSubtitleDialog] = useState('');
    const [firstnameError, setFirstnameError] = useState(false);
    const [lastnameError, setLastnameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [loading, setLoading] = React.useState(false);

    const openErrorDialog = () => {
        setErrorDialogOpen(true);
    };

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleRigester = async () => {
        try {

            if (!firstName && !lastName && !email) {
                setFirstnameError(true);
                setLastnameError(true);
                setEmailError(true);
                return;
            }

            if (!firstName || !lastName || (!email || !validateEmail(email))) {
                setFirstnameError(!firstName);
                setLastnameError(!lastName);
                setEmailError(!email && !validateEmail(email));
                return;
            }

            if (firstName) {
                setFirstnameError(false);
            }
            if (lastName) {
                setLastnameError(false);
            }

            if (email && validateEmail(email)) {
                setEmailError(false);
            }
            const formData = new FormData();
            formData.append('cmd', "frappe.core.doctype.user.user.sign_up")
            formData.append('full_name', firstName + ' ' + lastName);
            formData.append('email', email);
            formData.append('redirect_to', '');
            setLoading(true);

            const response = await axios.post(url, formData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            setLoading(false);
            if (response.status === 200) {
                if (response.data.message[0] === 1 || response.data.message[0] === 2) {
                    localStorage.setItem('registeredEmail', email);
                    window.location.href = '/checkEmail';

                    // if (response.data.message[0] === 2) {
                    //   // Redirect to verification page with email as query parameter
                    //   window.location.href = `/verification?email=${encodeURIComponent(email)}`;
                    // }

                }
                else if (response.data.message[0] === 0) {
                    setSubtitleDialog(response.data.message[1]);
                    openErrorDialog();
                }
            }
            else {
                setSubtitleDialog("An Error Occured");
                openErrorDialog();
            }


        } catch (error) {
            setLoading(false);
            // Handle errors
            setSubtitleDialog("An Error Occured");
            openErrorDialog();

            console.log(error);
        }
    };



    const handlePhoneNumberChange = (status, value, countryData, number, id) => {
        console.log(value); // Access the phone number value
        value = { phone };
        setPhone(number)
    };



    return (
        <div className='register'>
            <div className=' back register-container'>
                <div className="container wrapper">
                    <div className="sign-div">
                        <div className="sign-title" >
                            REGISTER
                        </div>
                        <div className="sign-subtitle">
                            dolor sit amet, consectetur adipiscing elit. Vestibulum nisl leo consectetur .
                        </div>
                        <div className="dashed-line"></div>


                        <div className="form-group margin-input" style={{ position: "relative" }}>
                            <div className="reg-title" >First Name</div>
                            <input type="text" className="form-control reg-input is-valid" id="First Name" placeholder="First Name" name="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            {firstnameError && <div className="invalid-feedback">FirstName is required.</div>}

                        </div>

                        <div className="form-group margin-input" style={{ position: "relative" }}>
                            <div className="reg-title" >Last Name</div>

                            <input type="text" className="form-control reg-input is-valid" id="Last Name" placeholder="Last Name" name="Last Name"
                                value={lastName}
                                onChange={(e) => setlastName(e.target.value)}
                            />
                            {lastnameError && <div className="invalid-feedback">LastName is required.</div>}

                        </div>

                        <div className="form-group margin-input" style={{ position: "relative" }}>
                            <div className="reg-title" >Email</div>

                            <input type="email" className="form-control reg-input ${emailError ? 'is-invalid' : 'is-valid'}`" id="Email" placeholder="Email" name="Email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setEmailError(e.target.value && !validateEmail(e.target.value));
                                }}
                            />
                            {emailError && !email && <div className="invalid-feedback">Email is required.</div>}
                            {emailError && email && <div className="invalid-feedback">Invalid email format.</div>}
                        </div>


                        {/* <div className="form-group margin-input" style={{position:"relative"}}>
      <div className="reg-title" >Phone Number</div>         
      <PhoneInput
        containerClassName="intl-tel-input"
        inputClassName="form-control  " //is-valid
        preferredCountries={['us', 'jp', 'ca']}
        separateDialCode
        onSelectFlag={handlePhoneNumberChange}
      />                
    </div> */}


                        <div className="dashed-line dashed-line-extra-marg"></div>
                        <button style={{ height: "45px" }} disabled={loading} className="sign-button" onClick={handleRigester}>
                            {loading == true ? (
                                <CircularProgress
                                    size={24}
                                />
                            ) :
                                <div>
                                    Sign Up
                                </div>}
                        </button>

                        <button
                            className="sign-button" style={{ color: "#101728", backgroundColor: "white", border: '1px solid #CFCFCF', }}>
                            <div className="display-google-button">
                                <  GoogleSvg />
                                <div style={{ paddingLeft: "6px" }}>
                                </div>

                                Sign Up with Google
                            </div>
                        </button>
                        <div className="account-text">
                            Do have an account? <span> <a href="/login"> Sign in now </a> </span>
                        </div>
                    </div>


                    <div className="login-back">

                        <div className="">

                        </div>
                    </div>



                </div>
                <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
                    <DialogContent>
                        <Typography variant="h6">Sorry</Typography>
                        <Typography variant="subtitle2">{subtitledialdg}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ backgroundColor: "#101728" }} variant="contained" onClick={() => setErrorDialogOpen(false)}>OK</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}


export default RegisterPage;