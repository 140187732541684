import React from 'react'
import "./Loading.css"

const Loading = () => {

    return (

        <div className="genuis-loading">
            <div className='loading-letters'>
                <div className='load-text'>
                    <div class="loader"></div>
                    <p>Loading</p>
                </div>
            </div>
        </div>
    )
}

export default Loading
