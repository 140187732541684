import React, { useState, useEffect } from 'react'
import "./ResetPasswordPage.css"
import { useLocation } from 'react-router-dom';
import { ReactComponent as CheckSvg } from '../../asset/images/svg/check.svg';
import { ReactComponent as PasswordSvg } from '../../asset/images/svg/password.svg';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";
import axios from 'axios';


export const ResetPasswordPage = ({ url }) => {
  const location = useLocation();
  const [registeredEmail, setRegisteredEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false); // State to track password mismatch error
  const [passwordRequirementsError, setPasswordRequirementsError] = useState(false); // State to track password requirements error

  useEffect(() => {
    // If using local storage
    const userEmail = localStorage.getItem('registeredEmail');
    const code = localStorage.getItem('VerificationCode');
    // // If using query parameters
    // const searchParams = new URLSearchParams(location.search);
    // const userEmail = searchParams.get('email');

    if (userEmail) {
      setRegisteredEmail(userEmail);
    }
    if (code) {
      setVerificationCode(code);
    }

  }, []);




  const openErrorDialog = () => {
    setErrorDialogOpen(true);
  };

  const handleResetPassword = async () => {

    // Check password requirements
    if (!/[A-Z]/.test(password) || !/[0-9]/.test(password) || !/[!@#$%^&*]/.test(password)) {
      setPasswordRequirementsError(true);
      return;
    }
    else {
      setPasswordRequirementsError(false);
    }

    if (password !== confirmPassword) {
      setPasswordMismatch(true);
      return;
    }
    else {
      setPasswordMismatch(false);
    }
    const formData = new FormData();
    formData.append('cmd', "frappe.core.doctype.user.user.reset_password")
    formData.append('new_password', password);
    formData.append('confirm_password', confirmPassword);
    formData.append('logout_all_sessions', 1);
    formData.append('email', registeredEmail);
    formData.append('key', verificationCode);
    try {
      const response = await axios.post(url, formData,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );
      if (response.status === 200) {
        //if (response.data.message == "Logged In") {
        window.location.href = '/home';
        //}
        // else {
        //   openErrorDialog();
        // }
      }
      else {
        openErrorDialog();
      }


    } catch (error) {
      // Handle errors
      openErrorDialog();

      console.log(error);
    }
  };





  return (
    <div className='reset'>
      <div className='verification-container'>
        <div className="back-img">

          <div className="ver-content">
            <div className="check-div">
              <CheckSvg />
            </div>
            <div className="ver-title">
              Email verified
            </div>
            <div className="ver-subtitle">
              please reset your password
            </div>

            <div className="form-group margin-input" style={{ position: "relative" }}>
              <div className="sign-icon-div">
                <PasswordSvg />
              </div>
              <input type="password" className="form-control reset-input is-valid" id="Password" placeholder="Password" name="Password"
                value={[password]}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {passwordRequirementsError && (
              <div className="error-message1">
                Password must contain symbols, numbers, and capital letters
              </div>
            )}

            <div className="form-group margin-input" style={{ position: "relative" }}>
              <div className="sign-icon-div">
                <PasswordSvg />
              </div>
              <input type="password" className="form-control reset-input is-valid" id="Confirm Password" placeholder="Confirm Password" name="Confirm Password"
                value={[confirmPassword]}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
            {passwordMismatch && (
              <div className="error-message">Passwords do not match</div>
            )}

            <button className="reset-password-button" onClick={handleResetPassword}>Continue</button>


          </div>
        </div>
        <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
          <DialogContent>
            <Typography variant="h6">Sorry,An Error Occured</Typography>
            <Typography variant="subtitle2">Please try again later.</Typography>
          </DialogContent>
          <DialogActions>
            <Button style={{ backgroundColor: "#101728" }} variant="contained" onClick={() => setErrorDialogOpen(false)}>OK</Button>
          </DialogActions>
        </Dialog>
      </div>    </div>
  );
};
export default ResetPasswordPage;