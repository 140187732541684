import { useEffect, useState } from 'react';
import './App.css';
import { Footer, NavPage } from "./Layouts/index";
import { Home, LoginPage, VerifyPage, RegisterPage, CheckEmailPage, SuccessPage, ResetPasswordPage, ProductsPage, ProductDetailsPage, CategoriesPage, ShoppingCartPage, CheckoutPage, SubCategoriesPage } from "./Pages/index";
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Loading } from './components/index';

function App() {

  useEffect(() => {
    AOS.init({
      once: true,
    });
  }, []);


  const [loading, setLoading] = useState(true);
  const url = "https://whatsapptest.inter-netsys.com";
  const apiMethod = "/api/method/geniusapi.www.api";
  const limit = 8;
  const pageUrl = window.location.pathname;
  const lastWordIndex = pageUrl.lastIndexOf("/") + 1;
  let pageName = pageUrl.substr(lastWordIndex);


  const [navbarData, setNavbarData] = useState([]);
  const [footerData, setFooterData] = useState({});
  const [logos, setLogos] = useState({});
  const [resultData, setResultData] = useState({});
  const [contentData, setContentData] = useState({});
  const [ItemData, setItemData] = useState({});
  const [CategoryData, setCategoryData] = useState({});

  const [innerPageData, setInnerPageData] = useState({});
  const [translationsData, setTranslationsData] = useState({});
  const [seoSettingsData, setSeoSettingsData] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('en');


  useEffect(() => {

    if (!["", "/", "home"].includes(pageName)
    ) {
      setLoading(false);

      return;
    }


    const fetchData = async (pageName) => {
      try {
        setLoading(true);
        var apiUrl = `${url}${apiMethod}.content_by_page_name?name=${pageName}`;


        const response = await fetch(apiUrl);
        const data = await response.json();
        // const navbar = data?.data?.navbar?.data || [];
        // const footer = data?.data?.footer || "";
        const result = data?.result || "";
        const content = data?.result?.content || "";
        const items = data?.result?.items || [];
        var categories;
        if (!pageUrl.includes("category/") && !pageUrl.includes("categories")) {
          categories = data?.result?.categories || [];
          setCategoryData(categories);

        }

        // const innerPage = data?.data?.inner_page || "";
        // const translations = data?.data?.translations || "";
        // const seoSettings = data?.data?.['seo-settings'] || "";
        // setNavbarData(navbar);
        // setFooterData(footer);
        setResultData(result);
        setContentData(content);
        setItemData(items);

        // setInnerPageData(innerPage);
        // setTranslationsData(translations);
        // setSeoSettingsData(seoSettings);
        setLogos(data?.data?.logos || "");
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (["", "/", "home"].includes(pageName)) {
      pageName = "home";
    }
    // else if ("products".includes(pageName)) {
    //   pageName += `&start=0&limit=${limit}`;
    // }
    // else if (pageUrl.includes("product/")) {
    //   let serviceId = pageUrl.substr(lastWordIndex);
    //   pageName = `product&product_name=${serviceId}`;
    // }
    // else if ("categories".includes(pageName)) {

    //   pageName += `&start=0&limit=${limit}`;
    // }

    // else if (pageUrl.includes("category/")) {
    //   let serviceId = pageUrl.substr(lastWordIndex);
    //   pageName = `category&parent_groups=${serviceId}`;
    // }
    fetchData(pageName);
  }, []);



  useEffect(() => {
    const updateFavicon = () => {
      const link = document.querySelector('link[rel="icon"]');
      // link.setAttribute('href', `${url + logos.logo_favicon}`);
      link.setAttribute('href', `http://localhost:3000/static/media/logo.51ddadac4a1158149f409df752a08fab.svg`);

    };

    const updateTitle = () => {
      const mainTitle = `${seoSettingsData?.main_title || ''} ${seoSettingsData?.separator || ''} ${innerPageData?.name || ''}`;
      const formattedTitle = mainTitle.trim() || 'Genuis';
      if (formattedTitle == undefined)
        document.title = "Genuis";
      else {
        document.title = formattedTitle;
      }

    };

    updateFavicon();
    updateTitle();
  }, [logos, pageName, seoSettingsData, innerPageData]);




  return (
    <div className="App">

      <BrowserRouter>
        {loading ? (
          <div>
          <NavPage url={url} />
          <Loading></Loading>
          <Footer />
          </div>

        ) :
          (
            <>
              <NavPage url={url} />
              <Routes>
                <Route index element={<Home contentData={contentData} innerPageData={innerPageData} itemsList={ItemData} categoryList={CategoryData} url={url} apiMethod={apiMethod} />} />
                <Route path="/home" element={<Home contentData={contentData} innerPageData={innerPageData} itemsList={ItemData} categoryList={CategoryData} url={url} apiMethod={apiMethod} />} />
                <Route path='/login' element={<LoginPage url={url} />} />
                <Route path='/register' element={<RegisterPage url={url} />} />
                <Route path='/checkEmail' element={<CheckEmailPage url={url} />} />
                <Route path='/verify' element={<VerifyPage />} />
                <Route path='/success' element={<SuccessPage />} />
                <Route path='/resetPassword' element={<ResetPasswordPage url={url} />} />
                <Route path='/products' element={<ProductsPage url={url} limit={limit} apiMethod={apiMethod} />} />
                <Route path="/product/:id" element={<ProductDetailsPage url={url} apiMethod={apiMethod} serviceId={pageUrl.substr(lastWordIndex)} />} />
                <Route path="/categories" element={<CategoriesPage url={url} limit={limit} apiMethod={apiMethod} />} />
                <Route path="/category/:id" element={<SubCategoriesPage url={url} apiMethod={apiMethod} />} />
                <Route path="/shoppingCart" element={<ShoppingCartPage url={url} apiMethod={apiMethod} />} />
                <Route path="/checkout" element={<CheckoutPage url={url} apiMethod={apiMethod} />} />

              </Routes>
              <Footer />
            </>
          )
        }
      </BrowserRouter>


    </div>
  );
}

export default App;
